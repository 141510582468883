// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-static-index-js": () => import("./../../../src/templates/static/index.js" /* webpackChunkName: "component---src-templates-static-index-js" */),
  "component---src-templates-static-legal-information-js": () => import("./../../../src/templates/static/legal-information.js" /* webpackChunkName: "component---src-templates-static-legal-information-js" */),
  "component---src-templates-static-not-found-js": () => import("./../../../src/templates/static/not-found.js" /* webpackChunkName: "component---src-templates-static-not-found-js" */),
  "component---src-templates-static-sitemap-js": () => import("./../../../src/templates/static/sitemap.js" /* webpackChunkName: "component---src-templates-static-sitemap-js" */)
}

